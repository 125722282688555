<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>商品管理</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('0')">
          <span>商品审核</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>商品分类</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>审核详情</span>
        </div>
        <div class="progress">
          <div class="progress-item success">
            <div class="stateTxt">
              <span>商品创建时间</span>
            </div>
            <div class="time">{{ userInfo[0].created_at }}</div>
          </div>
          <div
            class="progress-item"
            :class="userInfo[0].net_status == 1 ? 'success' : ''"
          ></div>
          <div
            class="progress-item"
            :class="userInfo[0].net_status == 1 ? 'success' : ''"
          >
            <div class="stateTxt">
              <span>商品图片审核时间</span>
            </div>
            <div class="time">{{ userInfo[0].updated_at }}</div>
          </div>
          <div
            class="progress-item"
            :class="userInfo[0].entity_check == 1 ? 'success' : ''"
          ></div>
          <div
            class="progress-item"
            :class="userInfo[0].entity_check == 1 ? 'success' : ''"
          >
            <div class="stateTxt">
              <span>实物鉴定时间</span>
            </div>
            <div class="time">{{ userInfo[0].entity_check_time }}</div>
          </div>
        </div>
        <div class="title mt-20">
          <span>用户信息</span>
        </div>
        <div class="user-table">
          <el-table :data="userInfo" style="width: 100%" border>
            <el-table-column
              prop="user.id"
              label="用户ID"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="image"
              label="用户头像"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <img
                  style="width: 80%"
                  :src="scope.row.user.avatarUrl"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="user.nickName"
              label="用户昵称"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="title mt-20">
          <span>商品信息</span>
        </div>
        <div class="shop-table">
          <el-table :data="userInfo" style="width: 100%" border>
            <el-table-column
              prop="id"
              label="商品ID"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="category.name"
              label="商品分类"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="商品名称"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="remarks" label="商品备注" align="center">
            </el-table-column>
          </el-table>
        </div>
        <div
          class="img-compress"
          @click="unloadFile"
          v-if="userInfo[0].file_json != null"
        >
          <i class="el-icon-download" style="padding-right: 20px"></i>
          <span>一键下载图片压缩包</span>
        </div>
        <div class="title mt-20">
          <span>商品图片</span>
        </div>
        <div
          class="shopimg-list"
          v-for="(item, index) in userInfo[0].file_json"
          :key="index"
        >
          <p>{{ item.name }}</p>
          <img :src="$store.state.imaUrl + item.files" alt="" />
        </div>
        <div class="shopimg-list" v-if="userInfo[0].file_json == null">
          <span>该商品尚未上传图片！</span>
        </div>
        <div class="title mt-20" v-if="userInfo[0].file_json != null">
          <span>图片审核</span>
        </div>
        <div class="picture-review" v-if="userInfo[0].file_json != null">
          <div class="left-region" v-if="userInfo[0].net_status == 0">
            <div>
              <el-radio v-model="radio" label="1">通过</el-radio>
              <el-radio v-model="radio" label="2">驳回</el-radio>
              <el-select
                size="mini"
                v-model="imgReject"
                placeholder="驳回原因"
                v-if="radio == 2"
              >
                <el-option
                  v-for="item in options"
                  :key="item.remark"
                  :label="item.remark"
                  :value="item.remark"
                >
                </el-option>
              </el-select>
            </div>
            <div class="mt-20" v-if="radio == 1">
              <span class="txt-red">*</span>
              <span>售出估价</span>
              <el-input
                onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                v-model="sold"
                placeholder="请输入金额"
              ></el-input>
              <span>元</span>
            </div>
            <div class="mt-20" v-if="radio == 1">
              <span class="txt-red">*</span>
              <span>寄卖估价</span>
              <el-input
                onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                v-model="consignment"
                placeholder="请输入金额"
              ></el-input>
              <span>元</span>
            </div>
            <div class="mt-20">
              <el-button type="primary" @click="imgSubmit">确定</el-button>
            </div>
          </div>
          <div class="left-region" v-else>
            <p v-if="userInfo[0].net_status == 1">已同意</p>
            <p v-if="userInfo[0].net_status == 2">驳回</p>
            <p v-if="userInfo[0].net_status == 2">
              驳回理由：{{ userInfo[0].remark }}
            </p>
          </div>
        </div>
        <div class="title mt-20">
          <span>交易类型</span>
        </div>
        <div class="mt-20">
          <span class="chushouBtn" v-if="userInfo[0].sell_type == 0"
            >待客户选择售卖类型</span
          >
          <span class="chushouBtn" v-if="userInfo[0].sell_type == 1">售出</span>
          <span class="jimaiBtn" v-if="userInfo[0].sell_type == 2">寄卖</span>
        </div>
        <div class="title mt-20" v-if="userInfo[0].sell_type != 0">
          <span>实物审核</span>
        </div>
        <div
          class="mt-20 physical-examination"
          v-if="
            userInfo[0].net_status == 1 &&
            userInfo[0].entity_check == 0 &&
            userInfo[0].sell_type != 0
          "
        >
          <el-radio-group v-model="material" style="margin-right: 20px">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="3">通过但有瑕疵</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
          <el-select
            size="mini"
            v-model="materialReject"
            placeholder="驳回原因"
            v-if="material == 2"
            lcass="ml-40"
          >
            <el-option
              v-for="item in options"
              :key="item.remark"
              :label="item.remark"
              :value="item.remark"
            >
            </el-option>
          </el-select>
          <div class="mt-20">
            <el-button type="primary" @click="materialClick">确定</el-button>
          </div>
        </div>
        <div class="mt-20 physical-examination" v-else>
          <p v-if="userInfo[0].entity_check == 1 && userInfo[0].appraisal_file == null">已同意</p>
          <p v-if="userInfo[0].appraisal_file != null">已同意但有瑕疵:</p>
          <div
            class="causes-of-defects-item"
            v-for="(item, index) in userInfo[0].appraisal_file"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <div class="causes-of-defects-item-img">
              <img :src="'http://weixin.maibaobao.shop/' + item.files" alt="" />
            </div>
          </div>
          <p v-if="userInfo[0].sell_auction_file != null">寄卖改价原因:</p>
          <div
            class="causes-of-defects-item"
            v-for="(item, index) in userInfo[0].sell_auction_file"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <div class="causes-of-defects-item-img">
              <img :src="'http://weixin.maibaobao.shop/' + item.files" alt="" />
            </div>
          </div>
          <p v-if="userInfo[0].entity_check == 2">驳回</p>
          <p v-if="userInfo[0].entity_check == 2">
            驳回理由：{{ userInfo[0].remark }}
          </p>
          <el-button
            type="primary"
            v-if="
              userInfo[0].sell_auction_file == null &&
              userInfo[0].sell_type == 2 &&
              userInfo[0].entity_check != 2
            "
            @click="centerDialogVisible2 = true"
            >修改寄卖价格</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      title="瑕疵原因"
      :visible.sync="centerDialogVisible"
      width="800px"
      center
    >
      <div class="reason">
        <div
          class="reason-item mt-20"
          v-for="(item, index) in fromList"
          :key="index"
        >
          <el-input
            v-model="item.files_name"
            size="mini"
            placeholder="驳回原因"
          ></el-input>
          <div class="from-item-upload">
            <el-upload
              ref="upload"
              class="upload-demo"
              :action="'url'"
              name="file"
              :data="fromList"
              list-type="picture"
              :auto-upload="false"
              :multiple="false"
              :headers="uploadHeaders"
              :on-change="
                (file, fileList) => {
                  uploadChange(file, fileList, index);
                }
              "
              :limit="1"
              :on-exceed="uploadExceed"
              :http-request="uploadFile"
              :on-remove="
                (file, fileList) => {
                  uploadRemove(file, fileList, index);
                }
              "
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
        </div>
        <div>
          售成交易价：<el-input
            v-model="money"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="operation">
        <el-button type="primary" size="mini" @click="add">添加</el-button>
        <el-button type="danger" size="mini" @click="remove">删除</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改寄卖价格"
      :visible.sync="centerDialogVisible2"
      width="800px"
      center
    >
      <div class="reason">
        <div
          class="reason-item mt-20"
          v-for="(item, index) in fromList"
          :key="index"
        >
          <el-input
            v-model="item.files_name"
            size="mini"
            placeholder="证明词"
          ></el-input>
          <div class="from-item-upload">
            <el-upload
              ref="upload"
              class="upload-demo"
              :action="'url'"
              name="file"
              :data="fromList"
              list-type="picture"
              :auto-upload="false"
              :multiple="false"
              :headers="uploadHeaders"
              :on-change="
                (file, fileList) => {
                  uploadChange(file, fileList, index);
                }
              "
              :limit="1"
              :on-exceed="uploadExceed"
              :http-request="uploadFile"
              :on-remove="
                (file, fileList) => {
                  uploadRemove(file, fileList, index);
                }
              "
            >
              <el-button size="small" type="primary">上传证明图</el-button>
              <span class="ml-50 txt-red">请上传正方形图片</span>
            </el-upload>
          </div>
        </div>
        <div>原寄卖价：{{ userInfo[0].auction_price }} 元</div>
        <div class="mt-20">
          修改寄卖价格：<el-input
            v-model="undataMoney"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="operation">
        <el-button type="primary" size="mini" @click="add">添加</el-button>
        <el-button type="danger" size="mini" @click="remove">删除</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="zmsubmitUpload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import leftList from "../../components/leftList.vue";
import topHeader from "../../components/header.vue";
export default {
  components: {
    leftList,
    topHeader,
  },
  inject: ["reload"],
  data() {
    return {
      flawIndex: 0,
      money: "",
      fromList: [
        {
          files_name: "",
          files: [],
        },
      ],
      centerDialogVisible: false,
      centerDialogVisible2: false,
      materialReject: "",
      imgReject: "",
      material: 1,
      consignment: "",
      sold: "",
      radio: "1",
      userInfo: [],
      isCollapse: false,
      starttime: "",
      endtime: "",
      shopiing: "",
      state: "",
      inpuValue: "",
      region: "",
      uploadHeaders: {
        "Remember-Token": localStorage.getItem("rememberToken"),
      },
      options: [],
      formDate: "",
      undataMoney: "",
    };
  },
  created() {
    this.getInfo();
    this.getRemark();
  },
  methods: {
    zmsubmitUpload() {
      let _this = this;
      for (let i = 0; i < this.fromList.length; i++) {
        // console.log(this.fromList.files[i].files);
        if (this.fromList[i].files_name == "") {
          this.$notify.error({
            title: "提示",
            message: "请填写证明词！",
          });
          return false;
        }
        if (this.fromList[i].files.length == 0) {
          this.$notify.error({
            title: "提示",
            message: "请上传证明图！",
          });
          return false;
        }
      }
      if (this.undataMoney == "") {
        this.$notify.error({
          title: "提示",
          message: "请填写修改价格！",
        });
        return false;
      }
      this.formDate = new FormData();
      console.log(_this.$refs);
      for (let i = 0; i < this.fromList.length; i++) {
        _this.$refs.upload[i].submit();
        this.formDate.append("files_name[]", this.fromList[i].files_name);
      }
      this.formDate.append("money", this.undataMoney);
      this.formDate.append("id", this.$route.query.id);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      this.$axios
        .post(
          "http://weixin.maibaobao.shop/api/change/entity_price",
          this.formDate,
          config
        )
        .then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "提示",
              message: "操作成功！",
              type: "success",
            });
            this.$router.push("/commodity");
          } else {
            this.$notify.error({
              title: "提示",
              message: res.data.message,
            });
          }
          // console.log(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    uploadRemove(file, fileList, index) {
      this.fromList[index].files = [];
    },
    uploadFile(file) {
      this.formDate.append("files[]", file.file);
      // console.log(this.formDate);
    },
    uploadChange(file, fileList, index) {
      // console.log(file, fileList, index);
      this.fromList[index].files = [];
      this.fromList[index].files.push(file.raw);
      // console.log(this.fromList.files[index]);
    },
    uploadExceed() {
      this.$notify({
        title: "警告",
        message: "只可选择一张图片",
        type: "warning",
      });
    },
    submitUpload() {
      let _this = this;
      for (let i = 0; i < this.fromList.length; i++) {
        // console.log(this.fromList.files[i].files);
        if (this.fromList[i].files_name == "") {
          this.$notify.error({
            title: "提示",
            message: "请填写瑕疵原因！",
          });
          return false;
        }
        if (this.fromList[i].files.length == 0) {
          this.$notify.error({
            title: "提示",
            message: "请上传示意图！",
          });
          return false;
        }
      }
      if (this.money == "") {
        this.$notify.error({
          title: "提示",
          message: "请填写修改价格！",
        });
        return false;
      }
      this.formDate = new FormData();
      console.log(_this.$refs);
      for (let i = 0; i < this.fromList.length; i++) {
        _this.$refs.upload[i].submit();
        this.formDate.append("files_name[]", this.fromList[i].files_name);
      }
      this.formDate.append("money", this.money);
      this.formDate.append("is_flaw", 1);
      this.formDate.append("entity_check", 1);
      this.formDate.append("id", this.$route.query.id);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      this.$axios
        .post("http://weixin.maibaobao.shop/api/entity/check", this.formDate, config)
        .then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "提示",
              message: "操作成功！",
              type: "success",
            });
            this.$router.push("/commodity");
          } else {
            this.$notify.error({
              title: "提示",
              message: res.data.message,
            });
          }
          // console.log(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    imgSubmit() {
      this.$post("goods/check", {
        id: this.$route.query.id,
        net_status: this.radio,
        remark: this.radio == 2 ? this.imgReject : "",
        sell_price: this.radio == 1 ? this.sold : "",
        auction_price: this.radio == 1 ? this.consignment : "",
      }).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "提示",
            message: "操作成功！",
            type: "success",
          });
          this.reload();
        } else {
          this.$notify.error({
            title: "提示",
            message: res.data.message,
          });
        }
      });
    },
    getRemark() {
      this.$get("get/remark", {
        num: 999,
      }).then((res) => {
        this.options = res.data.data.data;
      });
    },
    unloadFile() {
      if (this.userInfo[0].file_json == null) {
        this.$notify.error({
          title: "提示",
          message: "该商品未上传图片",
        });
      } else {
        this.$post("get/zip", {
          id: this.$route.query.id,
        }).then((res) => {
          if (res.data.status == 200) {
            window.location.href = this.$store.state.imaUrl + res.data.data;
          } else {
            this.$notify.error({
              title: "提示",
              message: res.data.message,
            });
          }

          console.log(res);
        });
      }
    },
    getInfo() {
      this.$get("get/backend_one", {
        id: this.$route.query.id,
      }).then((res) => {
        this.userInfo = [];
        if (res.data.data.file_json != null) {
          res.data.data.file_json = JSON.parse(res.data.data.file_json);
        }
        if (res.data.data.appraisal_file != null) {
          res.data.data.appraisal_file = JSON.parse(
            res.data.data.appraisal_file
          );
        }
        if (res.data.data.sell_auction_file != null) {
          res.data.data.sell_auction_file = JSON.parse(
            res.data.data.sell_auction_file
          );
        }
        this.userInfo.push(res.data.data);
      });
    },
    remove() {
      if (this.fromList.length > 1) {
        var m = this.fromList.slice();
        m.pop();
        this.fromList = m;
      } else {
        this.$notify.error({
          title: "提示",
          message: "至少声明一条原因",
        });
      }
    },
    add() {
      this.fromList.push({
        files_name: "",
        files: [],
      });
    },
    uploadClick(index) {
      this.flawIndex = index;
    },
    handleAvatarSuccess(res, file) {
      this.flawList[this.flawIndex].imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    materialClick() {
      if (this.material == 3) {
        this.centerDialogVisible = true;
      } else {
        this.$post("entity/check", {
          id: this.$route.query.id,
          is_flaw: 0,
          entity_check: this.material == 3 ? 1 : this.material,
          remark: this.material == 2 ? this.materialReject : "",
        }).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "提示",
              message: "操作成功！",
              type: "success",
            });
            this.$router.push("/commodity");
          } else {
            this.$notify.error({
              title: "提示",
              message: res.data.message,
            });
          }
        });
      }
    },
    menuitemClick(pushUrl) {
      // console.log(this.$route.path == pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/commodity") {
        this.$router.push("/commodity");
      } else if (index == 1) {
        this.$router.push("/classification");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleEdit(index, row) {
      this.$router.push({
        name: "knowledgeedit",
        params: {
          row: row,
        },
      });
      //   console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    //限制文本框只可输入数字
  },
};
</script>

<style lang="scss">
.causes-of-defects-item {
  font-size: 16px;

  &-img {
    margin-top: 15px;

    img {
      width: 150px;
      height: 150px;
    }
  }
}
.reason {
  width: 500px;
  display: inline-block;
  .el-input {
    width: 200px;
  }
}
.from-item-upload {
  margin: 20px 0;
}
.operation {
  display: inline-block;
  vertical-align: top;
}
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .physical-examination {
    padding: 0 100px;
  }
  .picture-review {
    // border: 1px solid;
    margin-top: 20px;
    padding: 0 100px;
    .left-region {
      display: inline-block;
      width: 700px;
      .el-input {
        width: 200px;
        margin: 0 20px;
      }
    }
    .right-region {
    }
  }
  .shopimg-list {
    margin-top: 20px;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .user-table {
    display: inline-block;
    width: 600px;
    padding-top: 20px;
  }
  .shop-table {
    display: inline-block;
    width: 800px;
    padding-top: 20px;
  }
  .img-compress {
    text-align: right;
    span,
    i {
      cursor: pointer;
    }
  }
  .progress {
    margin-top: 25px;
    &-item {
      //   border: 1px solid;
      display: inline-block;
      vertical-align: middle;
      width: calc(100% / 5);
      &:last-child {
        margin-right: 0px;
      }
      &:nth-child(2n) {
        position: relative;
        vertical-align: top;
        margin-top: 17px;
        background-color: rgb(139, 134, 134);
        height: 7px;
        border-radius: 100px;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          border: solid rgb(139, 134, 134);
          border-width: 0 5px 5px 0;
          transform: translate(-50%, -50%) rotate(-45deg);
          position: absolute;
          right: -10px;
          top: 50%;
        }
      }
      .stateTxt {
        text-align: center;
        span {
          display: inline-block;
          background-color: rgb(139, 134, 134);
          color: #fff;
          padding: 10px 0px;
          width: 200px;
          border-radius: 100px;
        }
      }
      .time {
        text-align: center;
        margin-top: 50px;
      }
    }
    .success {
      .stateTxt {
        span {
          background-color: #27b148;
        }
      }
      &:nth-child(2n) {
        position: relative;
        vertical-align: top;
        margin-top: 17px;
        background-color: #27b148;
        height: 7px;
        border-radius: 100px;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          border: solid #27b148;
          border-width: 0 5px 5px 0;
          transform: translate(-50%, -50%) rotate(-45deg);
          position: absolute;
          right: -10px;
          top: 50%;
        }
      }
    }
  }
  .date-search {
    margin-top: 30px;
    .el-button {
      margin-left: 40px;
      padding: 12px 55px;
    }
    .el-input {
      width: 200px;
    }
    .left,
    .right {
      display: inline-block;
    }
    .right {
      width: 1000px;
      vertical-align: top;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
